.router_link {
    text-decoration: none;
    font-size: 14px;
    margin-left: 10px;
    color: #1976d2;
}

.login_card {
    max-width: 550px;
    min-width: 550px;
}

.login_logo {
    width: 30px;
}
