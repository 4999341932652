/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap'); */
@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/public/fonts/ProductSans-Regular.ttf') ;
}

body {
  margin: 0;
  font-family: 'Product Sans';
  /* font-family: 'Open Sans', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #091827; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

