html {
    scroll-behavior: smooth;
  } 
.theme-light-change-btn {
    animation: ligh-theme-btn-spin 500ms linear;
}

.theme-dark-change-btn {
    animation: dark-theme-btn-spin 500ms linear;
}

@keyframes ligh-theme-btn-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes dark-theme-btn-spin {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}
.router-link {
    text-decoration: none;
    color: inherit;
}

.dropzone {
    height: 30vh;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 3px;
    border-color: gray;
    border-style: dashed;
    outline: none;
    transition: border .24s ease-in-out;
    margin-top: 5%;
}
.rejected-files{
    color: red;
}
.border-highlight{
    border-color: #2196f3;
}
.dropzone-upload-icon{
    transition: all .24s ease-in-out;
}

body::-webkit-scrollbar {
    scrollbar-color: rebeccapurple green;
}

.input-box-with-border-radius fieldset{ 
    border-radius: 30px;
}
.scroll-bar-none::-webkit-scrollbar{
    display: none;
}
.sidebar-nav-active {
    background-color: #2196f3;
}
/* .css-1bfesyr-MuiPaper-root-MuiDrawer-paper{
    z-index: 1201 !important;
    border-top-left-radius: 15px;
}
.css-18sg6k4-MuiPaper-root-MuiDrawer-paper{
    z-index: 1201 !important;
    border-top-left-radius: 15px;
} */
.css-1vlwt05-MuiPaper-root-MuiAppBar-root{
    box-shadow: none !important;
}
/* scroll button */
@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  .animate-flicker {
     -webkit-animation: flickerAnimation 3s infinite;
     -moz-animation: flickerAnimation 3s infinite;
     -o-animation: flickerAnimation 3s infinite;
      animation: flickerAnimation 3s infinite;
  }

  @keyframes fadeMove {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    50% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(50px);
    }
  }
  
  .fade-move-animation {
    animation: fadeMove 3s infinite;
    transition: opacity 0.5s;
    opacity: 0;
  }
  